<template>
    <Spin fix>
        <Icon
            type="ios-loading"
            size=24
            class="demo-spin-icon-load"
        ></Icon>
        <div>Loading</div>
    </Spin>
</template>

<script>
export default {
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("jaccLogin", this.$route.params.id);

      this.$router.push("/");
    }
  }
};
</script>

<style lang="less" scoped>
.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>